 import pro1 from "../assets/project1.png";
 import pro2 from "../assets/project2.png";
 import pro3 from "../assets/project3.png";

const ProjectCardData=[
    {
        imgsrc:pro1,
        tittle:"Ecommerce Website Design",
        text:"Thriving Facebook community and top-ranked podcast, Amy inspires a grounded, tangible and self-affirming sense of ''Wow! really can do this''  forover 100,000 online entrepreneurs. Best sellinh marketing  courses",
        view:"https://youtu.be/dX0GrDGLe9o"

    },
    {
        imgsrc:pro2,
        tittle:"LMS/Course Website Design",
        text:"Thriving Facebook community and top-ranked podcast, Amy inspires a grounded, tangible and self-affirming sense of ''Wow! really can do this''  forover 100,000 online entrepreneurs. Best sellinh marketing  courses",
        view:"https://youtu.be/jWFFkEdgC5M"       
    },
    {
        imgsrc:pro3,
        tittle:"Rymo Ecommerce Design",
        text:"Thriving Facebook community and top-ranked podcast, Amy inspires a grounded, tangible and self-affirming sense of ''Wow! really can do this''  forover 100,000 online entrepreneurs. Best sellinh marketing  courses",
        view:"https://youtube.com/shorts/Lch9o8qKl90?feature=share"       
    }
]

export default ProjectCardData;